import {request} from './api';

export function signIn(email, password) {
	return request('auth/user', {
		method: 'POST',
		body: {
			clientId: globalThis.appConfig.clientId,
			email: email,
			password: password
		}
	}, true);
}

export function validateToken(token) {
	return request('auth/token', {
		headers: {
			Authorization: `Bearer ${token}`
		}
	}, true);
}

export function getFirebaseAuth() {
	return request('auth/firebase');
}
