import {useState, useEffect} from 'react';
import useIsMounted from './useIsMounted';

const useDelay = (delay = 0) => {
	const [ready, setReady] = useState(!delay);
	const isMounted = useIsMounted();

	useEffect(() => {
		if (delay) {
			const timer = setTimeout(() => {
				if (isMounted()) {
					setReady(true);
				}
			}, delay);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [delay]);

	return ready;
};

export default useDelay;
